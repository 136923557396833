/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://4rs3b5cq7jgmljw7hkvznkykny.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vpuv4an6xbcvpczi437fr6bfqu",
    "aws_cloud_logic_custom": [
        {
            "name": "oneClickUnsubApi",
            "endpoint": "https://7p9xy48fn7.execute-api.eu-west-1.amazonaws.com/testprod",
            "region": "eu-west-1"
        },
        {
            "name": "subscriberConsentTrackingAPI",
            "endpoint": "https://xhsyt7u14c.execute-api.eu-west-1.amazonaws.com/testprod",
            "region": "eu-west-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "subscriber-consent-tracking-testprod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
