import React, { useState, useRef, useLayoutEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { staySubscribed } from "../graphql/mutations";
import { unsubscribe } from "../graphql/mutations";
import Success from "../modules/animation/Success";
import Error from "../modules/animation/Error";
import langueageDetails, { countries } from "../constants/languages";
import { isBrowser } from "react-device-detect";
import RadioButtonItem from "../modules/common/RadioButtonItem";


export default function UnsubReason(props) {

  const [unsubscribed, setUnsubscribed] = useState(false);
  const [unsubscribeFailed, setUnsubscribedFailed] = useState(false);
  var reason = "0";
  const uuid = props.uuid;
  const unsub = props.unsub;
  const realm = props.realm;
  const hash = props.hash;
  const country = props.country;

  async function handleUnsubClick() {
    checkButton();
    console.log('Value of Radio button: ' + reason)

    try {
      var response = await API.graphql(
        graphqlOperation(unsubscribe, {
          uuid: uuid,
          realm: realm,
          hash: hash,
          unsub: unsub,
          country: country,
          reason: reason
        })
      );
      var result = response.data.unsubscribe.success;
      if (result) {
        setUnsubscribed(result);
        setTimeout(function () {
          redirectCustomer();
        }, 3000);
      } else {
        setUnsubscribedFailed(true);
      }
    } catch (error) {
      console.log(error);
      setUnsubscribedFailed(true);
    }
  }

  async function handleStaySubClick() {
    try {
      await API.graphql(
        graphqlOperation(staySubscribed, {
          uuid: uuid
        })
      );
      redirectCustomer();
    } catch (error) {
      console.log(error);
      redirectCustomer();
    }
  }
  function redirectCustomer(forceRedirect = false) {
    //var appBase = "dplay";//realm === realms.questuk ? "discoveryplus" : "dplay";
    //var appUrl = "https://discoveryplus.com"     //`${appBase}://${realm}/page/home`;
    var webbUrl = "https://discoveryplus.com";
    window.location = webbUrl;
    
  }

  function checkButton() {
    var numberOfUnsubReasons = Object.values(langueageDetails[country].unsubReasons);
    for(var i in numberOfUnsubReasons){
      if(document.getElementById("radioButtonId"+i).checked) {   
        reason = document.getElementById("radioButtonId"+i).value;
      }
    }     
    
}   
  


  return (
    <div className="BlockGrid">
      <div className="Container">
        {unsubscribeFailed ? (
          <Error failed={unsubscribeFailed} />
        ) : (
          <>
            {unsubscribed ? (
              <Success unsubscribed={unsubscribed} />
            ) : (
              <>
                <div className="Info-box">
                  <div className="unsubHeadline">
                  <h4>{langueageDetails[country].unsubscribeReasonHeadline}</h4>
                  </div>
                </div>
                    <RadioButtonItem country ={country} unsubReasons={langueageDetails[country].unsubReasons} />
                <div className="ButtonsContainer">
                  <button
                    className="StaySubscribedButton"
                    onClick={() => handleStaySubClick()}
                  >
                    {langueageDetails[country].staySubscribedText}
                  </button>
                  <button
                    className="UnsubscribeButton"
                    onClick={() => handleUnsubClick()}
                  >
                    {langueageDetails[country].unsubscribeText}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
