import React from "react";
import successAnimation from "../../assets/successAnimation.json";
import Lottie from 'react-lottie-player'

export default function Success(props) {

  return (
    <div className="Success">
      <Lottie 
      loop={false}
      play={props.unsubscribed}
      animationData={successAnimation}
      rendererSettings={{
        preserveAspectRatio: "xMidYMid slice",
      }}/>
    </div>
  );
}
