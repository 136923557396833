import React from "react";

export default function RecommendationItem(props) {
  const languageObject = Object.values(props.unsubReasons); 
  var radioButtons  = []; 

  function createRadioButtons(){

    for(var i in languageObject){
      radioButtons.push(<label><input className="radio" type="radio" id={"radioButtonId"+i} value={languageObject[i]} name="reason" required /><span>{languageObject[i]} </span></label> );
  }
  return radioButtons;

}



  return (
    <div className="radioButtonsCenter" >                
        <div className="radioButtonsLeft" >
            {createRadioButtons()}
                </div>
              </div>
  );
}
