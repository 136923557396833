const countries = {
  dplus_se: "dplus_se",
  dplus_dk: "dplus_dk",
  dplus_no: "dplus_no",
  dplus_fi: "dplus_fi",
  dplus_uk: "dplus_uk",
  dplus_gb: "dplus_gb",
  dplus_nl: "dplus_nl",
  dplus_es: "dplus_es",
  dplus_it: "dplus_it",
  dplus_ie: "dplus_ie",
  dplus_de: "dplus_de",
  dplus_at: "dplus_at",
  dplus_us: "dplus_us",
  dplus_ca: "dplus_ca",
  dplus_ca_fr: "dplus_ca_fr", //added to support both english and french copy for Canada 
  dplus_br: "dplus_br",
  dplus_ph: "dplus_ph"
};

const languageDetails = {
  [countries.dplus_se]: {
    infoTitle: "Är du säker på att du vill sluta få epost från oss?",
    infoText:
      "På discovery+ erbjuder vi en gigantisk värld av underhållning och släpper hela tiden nytt exklusivt innehåll. Med epost från oss har du alltid koll på senaste nytt!",
    recommendedText: "Spana in våra tips för dig:",
    unsubscribeText: "Jag vill sluta få epost",
    staySubscribedText: "Jag vill fortsätta få epost",
  },
  [countries.dplus_dk]: {
    infoTitle: "Er du sikker på, at du vil afmelde vores nyhedsbrev?",
    infoText: "Med nyhedsbreve fra os, går du aldrig glip af vores anbefalinger eller tilbud på discovery+",
    recommendedText: "Anbefalinger til dig:",
    unsubscribeText: "Jeg vil gerne afmelde nyhedsbreve",
    staySubscribedText: "Jeg vil stadig gerne modtage nyhedsbreve",
  },
  [countries.dplus_no]: {
    infoTitle: "Er du sikker på at du vil melde deg av nyhetsbrev?",
    infoText:
      "Da vil du savne innhold som dette, og fantastiske tilbud fra discovery+!",
    recommendedText: "Anbefalt for deg:",
    unsubscribeText: "Jeg vil melde meg av uansett",
    staySubscribedText: "Jeg ønsker å holde meg oppdatert",
  },
  [countries.dplus_fi]: {
    infoTitle: "Oletko varma, ettet halua enää jatkossa saada meiltä sähköposteja discovery+-palvelun ajankohtaisista asioista?",
    infoText: "Saatat jäädä paitsi discovery+-palvelun viimeisimmistä ohjelmanostoista ja mahdollisista tarjouksista!",
    recommendedText: "Suosituksemme sinulle:",
    unsubscribeText: "En halua enää jatkossa saada näitä sähköposteja teiltä",
    staySubscribedText: "Haluan saada jatkossakin näitä sähköposteja teiltä",
  },
  [countries.dplus_uk]: {
    infoTitle: "Are you sure you want to unsubscribe from our emails?",
    infoText: "You’ll miss out on recommended shows especially for you, plus any limited time offers!",
    recommendedText: "Recommended for you:",
    unsubscribeText: "I want to unsubscribe",
    staySubscribedText: "I want to stay subscribed",
  },
  [countries.dplus_gb]: {
    infoTitle: "Are you sure you want to unsubscribe from our emails?",
    infoText: "You’ll miss out on recommended shows especially for you, plus any limited time offers!",
    recommendedText: "Recommended for you:",
    unsubscribeText: "I want to unsubscribe",
    staySubscribedText: "I want to stay subscribed",
  },
  [countries.dplus_nl]: {
    infoTitle: "Weet je zeker dat je je wilt uitschrijven?",
    infoText: "Je mist dan updates over alles wat discovery+ te bieden heeft!",
    recommendedText: "Aanbevolen voor jou:",
    unsubscribeText: "Ik wil me uitschrijven",
    staySubscribedText: "Ik wil op de hoogte blijven",
  },
  [countries.dplus_es]: {
    infoTitle: "¿Seguro que quieres darte de baja de nuestra lista de correos?",
    infoText: "¡Te perderás nuestras recomendaciones personalizadas de series, así como futuras ofertas de discovery+!",
    recommendedText: "Recomendados para ti:",
    unsubscribeText: "Quiero darme de baja de la lista de correos",
    staySubscribedText: "Quiero seguir recibiendo correos",
  },
  [countries.dplus_it]: {
    infoTitle: "Ci dispiace sapere che non vuoi più ricevere le nostre e-mail",
    infoText: "Ti perderai i consigli sulle migliori serie, le novità e le promozioni di discovery+ a te dedicate",
    recommendedText: "Consigliato per te:",
    unsubscribeText: "Non voglio ricevere le e-mail",
    staySubscribedText: "Voglio ricevere le e-mail",
  },[countries.dplus_ie]: {
    infoTitle: "Are you sure you want to unsubscribe from our emails in Discovery Plus?",
    infoText: "You’ll miss out on recommended shows especially for you, plus any limited time offers!",
    recommendedText: "Recommended for you:",
    unsubscribeText: "I want to unsubscribe",
    staySubscribedText: "I want to stay subscribed",
  },[countries.dplus_de]: {
    infoTitle: "Sind Sie sicher, dass Sie sich von unseren E-Mails abmelden möchten?",
    infoText: "So entgehen Ihnen speziell für Sie empfohlene Shows und befristete Angebote!",
    recommendedText: "Für Sie empfohlen",
    unsubscribeText: "Ich möchte mich abmelden",
    staySubscribedText: "Ich möchte angemeldet bleiben",
  },[countries.dplus_at]: {
    infoTitle: "Sind Sie sicher, dass Sie sich von unseren E-Mails abmelden möchten?",
    infoText: "So entgehen Ihnen speziell für Sie empfohlene Shows und befristete Angebote!",
    recommendedText: "Für Sie empfohlen",
    unsubscribeText: "Ich möchte mich abmelden",
    staySubscribedText: "Ich möchte angemeldet bleiben",
  },[countries.dplus_us]: {
    infoTitle: "Are you sure you want to unsubscribe from our emails in Discovery Plus?",
    infoText: "You’ll miss out on recommended shows especially for you, plus any limited time offers!",
    recommendedText: "Recommended for you:",
    unsubscribeText: "I want to unsubscribe",
    staySubscribedText: "I want to stay subscribed",
  },[countries.dplus_ca]: {
    infoTitle: "Are You Sure You Want to Unsubscribe?",
    infoText: "Stay subscribed to emails and receive personalized recommendations, updates, and special offers.",
    recommendedText: "Top Picks for You",
    unsubscribeText: "Unsubscribe",
    staySubscribedText: "Stay Subscribed",
  },[countries.dplus_ca_fr]: {
    infoTitle: "Êtes-vous sûr(e) de vouloir vous désabonner?",
    infoText: "Restez abonné(e) aux courriels pour recevoir des recommandations spécialement conçues pour vous, des nouvelles et des mises à jour, ainsi que des offres spéciales.",
    recommendedText: "Les meilleurs choix pour vous",
    unsubscribeText: "Se désabonner",
    staySubscribedText: "Continuer mon abonnement",
  },
  [countries.dplus_br]: {
    infoTitle: "Tem certeza de que deseja cancelar a assinatura de nossos e-mails no Discovery Plus?",
    infoText: "Você perderá programas recomendados especialmente para você e ofertas!",
    recommendedText: "Recomendado para você:",
    unsubscribeText: "Não receber novos e-mails",
    staySubscribedText: "Quero continuar inscrito",
    unsubscribeReasonHeadline:"Se prefere deixar de receber nossas atualizações, diga por que você cancelou a sua inscrição?",
    unsubReasons:{
    unsubReason1: "Os e-mails não são relevantes para mim",
    unsubReason2: "Recebo muitos e-mails e comunicações",
    unsubReason3: "Não tenho interesse no conteúdo da plataforma",
    unsubReason4: "Cancelei minha assinatura e não quero receber mais e-mails",
    unsubReason5: "Não tenho as informações que preciso pelos e-mails",
    unsubReason6: "Não tenho um motivo específico, apenas não quero mais receber os e-mails"
    }
  },[countries.dplus_ph]: {
    infoTitle: "Are You Sure You Want to Unsubscribe?",
    infoText: "Stay subscribed to emails for recommendations just for you, news and updates, and special offers.",
    recommendedText: "Top Picks for You",
    unsubscribeText: "Unsubscribe",
    staySubscribedText: "Stay Subscribed",
  }
};

export default languageDetails;

export { countries };
