import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { API, graphqlOperation } from "aws-amplify";
import { clickedOnShow } from "../../graphql/mutations";
import "react-lazy-load-image-component/src/effects/blur.css";
import discoveryplus from "../../assets/dplus_logo_large.png";
import DynamicFont from 'react-dynamic-font';
import { countriesPortraitImages } from "../../constants/countriesPortraitImages";


let initialWindowSizes = {
  innerHeight: 0,
  innerWidth: 0,
  height: 0,
  width: 0,
};
export default function RecommendationItem(props) {
  const [windowSizes, setSize] = useState(initialWindowSizes);
  const ref = useRef(null);
  const isBrowser = props.isBrowser ?? false;
  const country = props.country;// ?? "questuk";
  const recommendation = props.recommendation;
  const description = formatDescription(recommendation.description, country);
  const name = formatShowName(recommendation.name, country);
  const uuid = props.uuid;

  function updateSize() {
    var newSizes = {
      innerWidth: ref.current ? ref.current.offsetWidth : 0,
      innerHeight: ref.current ? ref.current.offsetHeight : 0,
      height: window.innerHeight,
      width: window.innerWidth,
    };
    setSize(newSizes);
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    updateSize();
  }, []);


  async function handleLinkClick() {
    try {
      await API.graphql(
        graphqlOperation(clickedOnShow, {
          uuid: uuid
        })
      );
    } catch (error) {
      console.log(error);
    }
    window.location = recommendation.webUrl;
  }

  function getReadMore(country) {
    switch (country) {
      case "dplus_no":
        return "Les mer";
      case "dplus_se":
        return "Läs mer";
      case "dplus_dk":
        return "Vis mere";
      case "dplus_fi":
        return "Lue lisää";
      case "dplus_nl":
        return "Lees meer";
      case "dplus_it":
        return "Leggi tutto";
      case "dplus_es":
        return "Lee mas";
      case "dplus_ie":
        return "More";
      case "dplus_de":
        return "Mehr";
      case "dplus_at":
        return "Mehr";
      case "dplus_gb":
        return "More";
      case "dplus_us":
        return "More";
      case "dplus_ca":
          return "More";
      case "dplus_ca_fr":
          return "More";     
      case "dplus_br":
          return "Mais"; 
      case "dplus_ph":
          return "More";           
      default:
      case "dplus_uk":
        return "More";
    }
  } 
  function formatShowName(name, country){
    if (country === "dplus_ca"){
      name = name.split('|')[0];
    }else if(country === "dplus_ca_fr"){
      var frenchName = name.split('|')[1];
      if(frenchName){
        name = frenchName;
      }else{
        name = name.split('|')[0]; //some shows are missing french shownames so therefore we use the english one.  
      }
    }
    return name;
  }

  function formatDescription(description, country) {
    var limit = isBrowser ? 130 : 110;
    var descriptionLength = isBrowser ? 116 : 96;
    if (country === "dplus_ca"){
      description = description.split('|')[0];
    }else if(country === "dplus_ca_fr"){
      var frenchDesc = description.split('|')[1];
      if(frenchDesc){
        description = frenchDesc;
      }else{
        description = description.split('|')[0]; //some shows are missing french description so therefore we use the english one.  
      }
    }
    if (description.length > limit) {
      description = description.substring(0, description.lastIndexOf(' ', descriptionLength))
      return (
        <h3>
          {description}...
          <a href={recommendation.webUrl} onClick={() => handleLinkClick()}>
            {getReadMore(country)}
          </a>
        </h3>
      );
    }
    return <h3>{description}</h3>;
  }
//    <div className="RecommendationsItem">
  return (
    <div className={countriesPortraitImages[country] ? 'RecommendationsItemPortrait' : 'RecommendationsItem'}>
      <a href={recommendation.webUrl} onClick={() => handleLinkClick()}>
        <LazyLoadImage
          className="RecommendationsItemImage"
          placeholderSrc={discoveryplus}
          src={recommendation.imageUrl}
          alt={name}
          style={{ display: "block" }}
          effect="blur"
          visibleByDefault = {(windowSizes.width < 770) ? true : false}
        />
      </a>
      <div className="DescriptionContainer">
        <div className="Description">
        <h4><DynamicFont content={name}/></h4>
          {description}
        </div>
        <div className="FadedImage">
          <img
            className="FlippedImage"
            src={recommendation.imageUrl}
            alt={recommendation.name}
          />
        </div>
      </div>
    </div>
  );
}
