/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const unsubscribe = /* GraphQL */ `
  mutation Unsubscribe(
    $uuid: ID!
    $realm: Realm!
    $hash: String!
    $unsub: String!
    $country: String!
    $reason: String!
  ) {
    unsubscribe(
      uuid: $uuid
      realm: $realm
      hash: $hash
      unsub: $unsub
      country: $country
      reason: $reason
    ) {
      success
    }
  }
`;
export const staySubscribed = /* GraphQL */ `
  mutation StaySubscribed($uuid: ID!) {
    staySubscribed(uuid: $uuid) {
      success
    }
  }
`;
export const clickedOnShow = /* GraphQL */ `
  mutation ClickedOnShow($uuid: ID!) {
    clickedOnShow(uuid: $uuid) {
      success
    }
  }
`;
