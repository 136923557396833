import React from "react";
import errorAnimation from "../../assets/errorAnimation.json"
import Lottie from 'react-lottie-player'

export default function Error(props) {
  return (
    <div className="Error">
      <Lottie 
      loop={false}
      play={props.failed}
      animationData={errorAnimation}
      rendererSettings={{
        preserveAspectRatio: "xMidYMid slice",
      }}/>
      </div>
  );
}
