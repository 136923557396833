import logo from '../../assets/dplus_logo.png';

export default function Header() {
  
    return (
      <header className="Header">
        <img
          src={logo}
          className="Logo"
          alt="logo"
        />
      </header>
    );
  }
  