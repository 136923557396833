import React, { useState, useRef, useLayoutEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import qs from "qs";
import { unsubscribe } from "../graphql/mutations";
import { staySubscribed } from "../graphql/mutations";
import { getRecommendations } from "../graphql/queries";
import Header from "../modules/common/Header";
import UnsubReasonPage from "../pages/UnsubReason";
import Recommendations from "../modules/recommendation/Recommendations";
import langueageDetails, { countries } from "../constants/languages";
import { isBrowser } from "react-device-detect";
import Success from "../modules/animation/Success";
import Error from "../modules/animation/Error";

export default function Unsubscribe(props) {
  const initialList = [];

  const queryParameters = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const [recommendationsAreEmpty, setrecommendationsAreEmpty] = useState(true);
  const [recommendations, setRecommendations] = useState(initialList);
  const [unsubReason, setUnsubReason] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [unsubscribeFailed, setUnsubscribedFailed] = useState(false);
  const uuid = queryParameters.uuid;
  const unsub = queryParameters.unsub;
  const realm = queryParameters.realm;
  const hash = queryParameters.hash;
  const country = queryParameters.country;
  var version = queryParameters.version;

  if (!country || !uuid || !unsub || !realm || !hash || !countries[country]) {
    redirectCustomer(true);
  }
  async function fetchRecommendations() {
    if(!version){
      version = "0";
    }
    try {
      var response = await API.graphql(
        graphqlOperation(getRecommendations, {
          uuid: uuid,
          realm: realm,
          hash: hash,
          country:country,
          version: version
        })
      );
      if (!response.data.getRecommendations) {
          //redirectCustomer(true); removing this because it redirects subscribers when refreshing the page. 
          setrecommendationsAreEmpty(false); //added this to support scenarios were we get a null response due to "Some of the recommendations are not related to target country".
          langueageDetails[country].recommendedText="";
      }else if (response.data.getRecommendations){
        if(response.data.getRecommendations.length === 0){
        langueageDetails[country].recommendedText="";
        setrecommendationsAreEmpty(false); 
        }else{
          setrecommendationsAreEmpty(false); 
        }
      }else{
        setrecommendationsAreEmpty(false); 
      }
      return response.data.getRecommendations;
    } catch (error) {
      langueageDetails[country].recommendedText="";
      setrecommendationsAreEmpty(false); //setting value to false because we want to render just the copy
      //redirectCustomer(true);
    }
  }

  function goToUnsubReasonOrUnsubscribe(country){
    if (country === "dplus_br"){
      setUnsubReason(true);
    }else{
      handleUnsubClick(); 
    }
  }

  async function handleStaySubClick() {
    try {
      await API.graphql(
        graphqlOperation(staySubscribed, {
          uuid: uuid
        })
      );
      redirectCustomer();
    } catch (error) {
      console.log(error);
      redirectCustomer();
    }
  }
  async function handleUnsubClick() {
    try {
      var response = await API.graphql(
        graphqlOperation(unsubscribe, {
          uuid: uuid,
          realm: realm,
          hash: hash,
          unsub: unsub,
          country: country,
          reason: "0"
        })
      );
      var result = response.data.unsubscribe.success;
      if (result) {
        setUnsubscribed(result);
        setTimeout(function () {
          redirectCustomer();
        }, 3000);
      } else {
        setUnsubscribedFailed(true);
      }
    } catch (error) {
      console.log(error);
      setUnsubscribedFailed(true);
    }
  }





  function redirectCustomer(forceRedirect = false) {
    //var appBase = "dplay";//realm === realms.questuk ? "discoveryplus" : "dplay";
    //var appUrl = "https://discoveryplus.com"     //`${appBase}://${realm}/page/home`;
    var webbUrl = "https://discoveryplus.com";
    window.location = webbUrl;
    
  }

  const isFirstRun = useRef(true);
  useLayoutEffect(() => {
    async function fetchData() {
      var recommendations = await fetchRecommendations();
      setRecommendations(recommendations);
      langueageDetails[country].recommendedText=recommendations? langueageDetails[country].recommendedText : "";
      
      try{      
      if(recommendations.length === 0){
        langueageDetails[country].recommendedText= "";
      }
    }catch(error){
      langueageDetails[country].recommendedText= "";
    }
    }

    if (isFirstRun.current) {
      fetchData();
      isFirstRun.current = false;
      return;
    }
  });

  return (
    <div className="BlockGrid">
      <Header />
      <div className="Container">
      {unsubscribeFailed ? (
          <Error failed={unsubscribeFailed} />
        ) : (
          <>
            {unsubscribed ? (
              <Success unsubscribed={unsubscribed} />
            ) : (
              <>  
            {unsubReason ? (
              <UnsubReasonPage uuid={uuid} unsub={unsub} realm={realm} hash={hash} country={country}   />
            ) :(<>
            {recommendationsAreEmpty ? (
                      <span></span>
                  ) :  (
              <>
                <div className="Info-box">
                  <h4>{langueageDetails[country].infoTitle}</h4>
                  <h3>{langueageDetails[country].infoText}</h3>
                </div>
                
                <h4 className="RecommendationsTitle">
                  {langueageDetails[country].recommendedText}
                </h4>
                
                <Recommendations
                  recommendations={recommendations}
                  isBrowser={isBrowser}
                  country={country}
                  uuid={uuid}
                />
                <div className="ButtonsContainer">
                  <button
                    className="StaySubscribedButton"
                    onClick={() => handleStaySubClick()}
                  >
                    {langueageDetails[country].staySubscribedText}
                  </button>
                  <button
                    className="UnsubscribeButton"
                    onClick={() => goToUnsubReasonOrUnsubscribe(country)}
                  >
                    {langueageDetails[country].unsubscribeText}
                  </button>
                </div>
              </>
              )}
            </>
            )}
          </>
          )}
        </>
      )}
      </div>
    </div>
  );
}
