/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRecommendations = /* GraphQL */ `
  query GetRecommendations(
    $uuid: ID!
    $realm: Realm!
    $hash: String!
    $country: String!
    $version: String!
  ) {
    getRecommendations(
      uuid: $uuid
      realm: $realm
      hash: $hash
      country: $country
      version: $version
    ) {
      name
      id
      webUrl
      imageUrl
      appUrl
      description
      countryId
    }
  }
`;
