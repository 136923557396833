import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import Unsubscribe from "./pages/Unsubscribe";

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/">
                 <Switch>
                   <Route path="/" exact component={Unsubscribe}/>
                   <Route path="/" render={() => (window.location = "https://discoveryplus.com")} />
                 </Switch>
        </Route>
      </Router>
    </div>
  );
}

export default App;
