import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import RecommendationItem from "./RecommendationItem";
import { countriesPortraitImages } from "../../constants/countriesPortraitImages";


let initialWindowSizes = {
  innerHeight: 0,
  innerWidth: 0,
  height: 0,
  width: 0,
};

export default function Recommendations(props) {
  const [windowSizes, setSize] = useState(initialWindowSizes);
  const ref = useRef(null);
  const isBrowser = props.isBrowser ?? false;
  const country = props.country;
  const uuid = props.uuid;
  const nrOfRecommendations = countriesPortraitImages[country] ? 6 : 4; 
  const recommendations = props.recommendations?.slice(0, nrOfRecommendations); 


  function updateSize() {
    var newSizes = {
      innerWidth: ref.current ? ref.current.offsetWidth : 0,
      innerHeight: ref.current ? ref.current.offsetHeight : 0,
      height: window.innerHeight,
      width: window.innerWidth,
    };
    setSize(newSizes);
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    updateSize();
  }, []);

  return (
    <div className="RecommendationsContainer" ref={ref}>
      {windowSizes.width >= 771 &&
        recommendations?.map((recommendation, i) => {
          return (
            <RecommendationItem
              key={recommendation.id}
              recommendation={recommendation}
              isBrowser={isBrowser}
              country={country}
              windowSizes={windowSizes}
              uuid={uuid}
            />
          );
        })}
      {windowSizes.width < 770 && (
        <>
          <Carousel
            className="RecommendationCarousel"
            itemWidth={windowSizes.innerWidth}
            centered
            autoPlay={4000}
            stopAutoPlayOnHover={true}
            animationSpeed={2000}
            draggable={false}
            infinite
            // dividing nrOfRecommendations by 2 since we are using two carousels for mobile 
            slides={recommendations?.slice(0, (nrOfRecommendations/2)).map((recommendation, i) => {
              return (
                <RecommendationItem
                  key={recommendation.id}
                  recommendation={recommendation}
                  isBrowser={isBrowser}
                  country={country}
                  windowSizes={windowSizes}
                  uuid={uuid}
                />
              );
            })}
          />
          <Carousel
            className="RecommendationCarousel"
            itemWidth={windowSizes.innerWidth}
            centered
            autoPlay={4000}
            animationSpeed={2000}
            stopAutoPlayOnHover={true}
            draggable={false}
            infinite
            slides={recommendations?.slice((nrOfRecommendations/2), nrOfRecommendations).map((recommendation, i) => {
              return (
                <RecommendationItem
                  key={recommendation.id}
                  recommendation={recommendation}
                  isBrowser={isBrowser}
                  country={country}
                  windowSizes={windowSizes}
                  uuid={uuid}
                />
              );
            })}
          />
        </>
      )}
    </div>
  );
}
